import { gql } from '@apollo/client';

export const SIGNUP_MUTATION = gql`
    mutation login($key: String!, $password: String!, $isOAuth: Boolean) {
        login(loginInput: { key: $key, password: $password, isOAuth: $isOAuth }) {
            message {
                message
            }
            user {
                id
                username
                email
                resetToken
                acceptPrivacy
            }
        }
    }
`;

export const ACCESS_ACCOUNT_MUTATION = gql`
    query access($user: String) {
        accesses(user: $user) {
            updates
            appVersion
            list {
                id
                user {
                    id
                    username
                    email
                    resetToken
                    acceptPrivacy
                }
                account {
                    id
                    name
                    config
                    listOfBrands
                    listOfCategories
                    defaultBrand
                    websites {
                        id
                        alias
                        name
                        url
                        data
                    }
                    website {
                        id
                        alias
                        name
                        data
                        url
                    }
                    components {
                        name
                        allowed
                    }
                }
            }
        }
    }
`;

export const USER_SESSION_MUTATION = gql`
    query SessionUser {
        SessionUser {
            id
            username
            email
        }
    }
`;

export const CHECK_PRIVACY_ACCEPT = gql`
    query checkPrivacyAccept($user: String!) {
        checkPrivacyAccept(user: $user) {
            accepted
            error
        }
    }
`;

export const ADD_USER_ACCEPT_PRIVACY = gql`
    mutation addUserAcceptPrivacy($user: String!, $ip: String, $address: String) {
        addUserAcceptPrivacy(user: $user, ip: $ip, address: $address) {
            accepted
            changed
            error
        }
    }
`;

export const LOGOUT_MUTATION = gql`
    mutation logout {
        logout {
            message {
                path
                message
                type
                code
            }
            user {
                id
                username
                email
                admin
                resetToken
                password
                acceptPrivacy
            }
            errors
        }
    }
`;
