import { useState } from 'react';
import { Theme, Container, styled, Box, useMediaQuery } from '@mui/material';

import { Outlet } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { TopbarHeight } from 'theme/theme-variable';
import Header from './Header/Header';
import Sidebar from './SideBar/SideBar';
import Footer from './Footer/Footer';

const MainWrapper = styled('div')(() => ({
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
    width: '100%'
}));

const PageWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
        paddingTop: TopbarHeight
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: '64px'
    }
}));

function MainLayout() {
    const { isDark } = useAppSelector((state: RootState) => state.auth);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const [isOpenDesktopSideBar, setIsOpenDesktopSideBar] = useState<boolean>(true);
    const [isOpenMobileSideBar, setIsOpenMobileSideBar] = useState<boolean>(false);

    const handleOpenDesktopDrawer = () => {
        setIsOpenDesktopSideBar(!isOpenDesktopSideBar);
    };

    const handleCloseDesktopDrawer = () => {
        setIsOpenDesktopSideBar(false);
    };

    const handleOpenMobileDrawer = () => {
        setIsOpenMobileSideBar(true);
    };

    const handleCloseMobileDrawer = () => {
        setIsOpenMobileSideBar(false);
    };

    return (
        <MainWrapper>
            <Header
                sx={{
                    paddingLeft: isOpenDesktopSideBar && lgUp ? '0' : '',
                    backgroundColor: isDark ? '#20232a' : '#ffffff',
                    boxShadow: '0px 7px 30px 0px rgb(90 114 123 / 11%)'
                }}
                handleChangeDesktopDrawer={handleOpenDesktopDrawer}
                handleOpenMobileDrawer={handleOpenMobileDrawer}
                handleCloseMobileDrawer={handleCloseMobileDrawer}
                handleCloseDesktopDrawer={handleCloseDesktopDrawer}
            />

            <Sidebar
                isSidebarOpen={isOpenDesktopSideBar}
                isMobileSidebarOpen={isOpenMobileSideBar}
                handleCloseMobileDrawer={handleCloseMobileDrawer}
            />

            <PageWrapper>
                <Container
                    maxWidth={false}
                    sx={{
                        paddingTop: '20px',
                        paddingLeft: isOpenDesktopSideBar && lgUp ? '310px!important' : ''
                    }}
                >
                    <Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
                        <Outlet />
                    </Box>
                    <Footer />
                </Container>
            </PageWrapper>
        </MainWrapper>
    );
}

export default MainLayout;
