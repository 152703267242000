import { Box, Container, Typography } from '@mui/material';

function AuthFooter(): JSX.Element {
    const todayDate: Date = new Date();

    return (
        <Container>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" component="span">
                    Copyright © {todayDate.getFullYear()} CRAWLO Data Intelligence Ltd.
                </Typography>
            </Box>
        </Container>
    );
}

export default AuthFooter;
