import { Box, Grid, Card, Theme, SxProps, Link } from '@mui/material';

import AuthFooter from './AuthFooter';
import { useNavigate } from 'react-router-dom';

type AuthWrapperProps = {
    children: string | JSX.Element | JSX.Element[];
    sxCard?: SxProps<Theme> | undefined;
    showSSOLogin?: boolean;
};

function AuthWrapper({ children, showSSOLogin, sxCard = { maxWidth: { xs: 400, lg: 475 } } }: AuthWrapperProps): JSX.Element {
    const navigate = useNavigate();
    const handleMMLogin = () => {
        navigate('/login/sso');
    };

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: '100vh'
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
                    >
                        <Grid item>
                            <Card
                                sx={{
                                    ...sxCard,
                                    margin: { xs: 2.5, md: 3 },
                                    '& > *': {
                                        flexGrow: 1,
                                        flexBasis: '50%'
                                    }
                                }}
                            >
                                <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>{children}</Box>
                            </Card>
                            {showSSOLogin && (
                                <Box sx={{ pl: 5, mt: '-15px' }} justifyContent="center" alignItems="center">
                                    <Link
                                        onClick={handleMMLogin}
                                        component="button"
                                        variant="body2"
                                        color={'text.primary'}
                                        underline="always"
                                    >
                                        Log in via SSO
                                    </Link>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} justifyContent="center" alignItems="center"></Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </Box>
    );
}

export default AuthWrapper;
