import { Dialog, DialogTitle, Box, DialogContent, DialogActions, Link, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';

interface PrivacyConditionsModalProps {
    open: boolean;
    loading: boolean;
    handleClose: () => void;
    handleClick: (values: any) => void;
}

function PrivacyConditionsModal({ open, loading, handleClose, handleClick }: PrivacyConditionsModalProps) {
    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { borderRadius: '20px' } }}>
            <DialogTitle>
                <Typography variant="h5" component="p">
                    We are updating our terms and conditions
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <Box>
                    <Typography variant="h2" sx={{ mb: '15px' }} component="p">
                        Terms and Conditions
                    </Typography>
                    <Typography variant="h5" sx={{ mb: '15px' }} component="p">
                        Welcome to the V3 of Crawlo Suite 360º
                    </Typography>
                    <Typography component="p">
                        These terms and conditions (Agreement) govern your use of Crawlo Suite 360º (Service) provided by Crawlo Data
                        Intelligence Ltd (We or Us). By accessing and using the Service, you agree to be bound by this Agreement.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        1 . Data Collection and Usage
                    </Typography>
                    <Typography component="p">
                        1.1 Personal Data: In order to provide access to the Service, we collect and process personal information, including
                        but not limited to names, email addresses, and user preferences. The lawful basis for processing this data is
                        [specify lawful basis, e.g., consent, legitimate interest].
                    </Typography>
                    <Typography component="p">
                        1.2 Purpose of Processing: We collect and use personal data for the following purposes: Providing access to the
                        Service and maintaining user accounts. Improving and enhancing user experience. Communicating with users regarding
                        updates, new features, and relevant information about the Service. Conducting statistical analysis and generating
                        anonymous aggregated reports.
                    </Typography>
                    1.3 Data Sharing: We do not sell or rent personal data to third parties.
                    <Typography component="p">
                        1.4 Data Retention: We retain personal data for as long as necessary to fulfill the purposes outlined in this
                        Agreement.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        2 . User Rights
                    </Typography>
                    <Typography component="p">
                        2.1 Right to Access and Rectify: You have the right to access and correct your personal data held by us. You can
                        update your information within your account settings or by contacting our support team.
                    </Typography>
                    <Typography component="p">
                        2.2 Right to Erase and Restrict Processing: You may request the erasure or restriction of your personal data in
                        accordance with applicable data protection laws. However, certain data may be retained as necessary for legal
                        obligations or legitimate business purposes.
                    </Typography>
                    <Typography component="p">
                        2.3 Right to Object: You have the right to object to the processing of your personal data on grounds relating to
                        your particular situation, unless we can demonstrate compelling legitimate grounds that override your rights.
                    </Typography>
                    <Typography component="p">
                        2.4 Right to Data Portability: Upon request, we will provide you with a copy of your personal data in a structured,
                        commonly used, and machine-readable format, where technically feasible.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        3 . Data Security
                    </Typography>
                    <Typography component="p">
                        3.1 Security Measures: We implement appropriate technical and organizational measures to protect personal data
                        against unauthorized access, loss, alteration, or destruction.
                    </Typography>
                    <Typography component="p">
                        3.2 Confidentiality: We maintain strict confidentiality obligations for personnel who process personal data and
                        ensure they are aware of the importance of data protection and their responsibilities.
                    </Typography>
                    <Typography component="p">
                        3.3 Data Storage: Your personal data collected through the Service is stored on servers located within the European
                        Economic Area (EEA). By using the Service, you acknowledge and agree that your personal data may be transferred and
                        stored within the EEA.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        4 . Cookies and Tracking Technologies
                    </Typography>
                    <Typography component="p">
                        4.1 Cookies: We use cookies and similar tracking technologies to enhance your user experience. By using the Service,
                        you consent to our use of cookies in accordance with our [Cookie Policy, hyperlink to policy].
                    </Typography>
                    <Typography component="p">
                        4.2 Google Analytics: We also utilize Google Analytics, a web analytics service provided by Google, Inc. (Google).
                        Google Analytics uses cookies to analyze website traffic and usage patterns. The information generated by the
                        cookies about your use of the Service (including your IP address) will be transmitted to and stored by Google on
                        servers in various locations globally. Google will use this information for the purpose of evaluating your use of
                        the Service, compiling reports on website activity for us, and providing other related services.
                    </Typography>
                    <Typography component="p">
                        4.3 Google Analytics Opt-Out: You may choose to opt-out of Google Analytics by using the Google Analytics Opt-Out
                        Browser Add-on, available at{' '}
                        <Link href="https://tools.google.com/dlpage/gaoptout" target="_blank" underline="none">
                            https://tools.google.com/dlpage/gaoptout
                        </Link>{' '}
                        Please note that opting out of Google Analytics may impact certain features and functionalities of the Service.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        5 . Updates and Communications
                    </Typography>
                    <Typography component="p">
                        5.1 Communication: By accepting these terms and conditions, you consent to receive communications from us regarding
                        important updates, new features, and relevant information about the Service. You can manage your communication
                        preferences within your account settings or by contacting our support team.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        6 . Governing Law and Jurisdiction
                    </Typography>
                    <Typography component="p">
                        This Agreement shall be governed by and construed in accordance with the laws of The Republic of Cyprus. Any
                        disputes arising from or relating to this Agreement shall be subject to the exclusive jurisdiction of the courts in
                        [Jurisdiction]. Please review our Privacy Policy{' '}
                        <Link href="https://www.crawlo.com/Privacy" target="_blank" underline="none">
                            https://www.crawlo.com/Privacy
                        </Link>{' '}
                        for further information on how we handle personal data.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        7 . Changes to Terms and Conditions
                    </Typography>
                    <Typography component="p">
                        We reserve the right to modify or update these terms and conditions at any time. Any changes will be effective
                        immediately upon posting the revised version on our website. We encourage you to review the terms periodically to
                        stay informed of any updates.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        8 . Severability
                    </Typography>
                    <Typography component="p">
                        If any provision of this Agreement is found to be invalid, illegal, or unenforceable, the remaining provisions shall
                        continue in full force and effect.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        9 . Entire Agreement
                    </Typography>
                    <Typography component="p">
                        This Agreement constitutes the entire understanding between you and us regarding the use of the Service and
                        supersedes any prior agreements or understandings.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: '10px', ml: '10px' }} component="p">
                        10 . Contact Us
                    </Typography>
                    <Typography component="p">
                        If you have any questions, comments, or concerns about these terms and conditions or our data practices, please
                        contact us at: CRAWLO DATA INTELLIGENCE LTD Efesou, 95283 Paralimni, Cyprus Email:{' '}
                        <Link href="mailto: hello@crawlo.com" underline="none">
                            hello@crawlo.com
                        </Link>
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={loading} onClick={handleClick} variant="contained" color="primary">
                    Accept terms and conditions
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default PrivacyConditionsModal;
