import { Navigate } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import AuthGuard from 'common/auth-guard';
import Loadable from 'components/common/UI/Loadable';
import lazyWithRetry from 'hooks/lazyWithRetry';
import ProtectedRoute from './ProtectedRoute';
import ErrorPage from 'views/ErrorPage';
import BetterWayPage from 'views/dashboard/BetterWayView/BetterWayPage';
import SSOLogin from 'views/auth/SSOLogin';

const ContentOverviewPage = Loadable(lazyWithRetry(() => import('views/content/Overview/ContentOverviewPage')));
const ContentAnalyticsPage = Loadable(lazyWithRetry(() => import('views/content/ContentAnalytics/ContentAnalyticsPage')));
const TitleAnalysisPage = Loadable(lazyWithRetry(() => import('views/content/TitleAnalysis/TitleAnalysisPage')));
const RatingPage = Loadable(lazyWithRetry(() => import('views/content/Rating/RatingPage')));
const QualityScorePage = Loadable(lazyWithRetry(() => import('views/content/QualityScore/QualityScorePage')));
const ContentDetailsPage = Loadable(lazyWithRetry(() => import('views/content/ContentDetails/ContentDetailsPage')));

const NewProducts = Loadable(lazyWithRetry(() => import('views/dashboard/NewProducts/NewProductsPage')));
const ListingWizard = Loadable(lazyWithRetry(() => import('views/dashboard/ListingWizard/ListingWizardPage')));
const GoogleListing = Loadable(lazyWithRetry(() => import('views/dashboard/GoogleListing/GoogleListingWizardPage')));
const FreshCatalog = Loadable(lazyWithRetry(() => import('views/dashboard/FreshCatalogView/CatalogPage')));
const AvailabilityCatalogPage = Loadable(lazyWithRetry(() => import('views/dashboard/FreshCatalogView/AvailabilityCatalogPage')));
const ShippingCatalogPage = Loadable(lazyWithRetry(() => import('views/dashboard/FreshCatalogView/ShippingCatalogPage')));
const OpportunitiesPage = Loadable(lazyWithRetry(() => import('views/dashboard/OpportunitiesView/OpportunitiesPage')));
const GfkOpportunitiesPage = Loadable(lazyWithRetry(() => import('views/dashboard/GFKOpportunitiesView/GFKOpportunitiesPage')));
const MarketSharePage = Loadable(lazyWithRetry(() => import('views/dashboard/MarketShareView/MarketSharePage')));
const MarketShareByBrandsPage = Loadable(lazyWithRetry(() => import('views/dashboard/MarketShareByBrandsView/MarketShareByBrandsPage')));
const ExclusiveProductsPage = Loadable(lazyWithRetry(() => import('views/dashboard/ExclusiveProducts/ExclusiveProductsPage')));
const NewlyDiscoveredPage = Loadable(lazyWithRetry(() => import('views/dashboard/NewlyDiscovered/NewlyDiscoveredPage')));
const AmazonBestSellerPage = Loadable(lazyWithRetry(() => import('views/dashboard/AmazonBestSeller/AmazonBestSellerPage')));
const BestRated = Loadable(lazyWithRetry(() => import('views/dashboard/BestRated/BestRatedPage')));
const CampaignManager = Loadable(lazyWithRetry(() => import('views/dashboard/CampaignManagerAnalytics/campaignManagerPage')));
const CompetitorPricing = Loadable(lazyWithRetry(() => import('views/dashboard/CompetitorPricing/competitorPricingPage')));
const DailyChanges = Loadable(lazyWithRetry(() => import('views/dashboard/DailyChanges/DailyChangesPage')));
const MultivariateAnalysis = Loadable(lazyWithRetry(() => import('views/dashboard/MultivariateAnalysisView/MultivariateAnalysisPage')));
const OverviewPage = Loadable(lazyWithRetry(() => import('views/dashboard/AssortmentAnalytics/OverviewPage')));
const Relevance = Loadable(lazyWithRetry(() => import('views/dashboard/RelevanceView/RelevancePage')));
const AllGFKProducts = Loadable(lazyWithRetry(() => import('views/dashboard/AllGFKProducts/AllGFKProductsPage')));
const ExportationListPage = Loadable(lazyWithRetry(() => import('views/dashboard/ExportationListView/ExportationListPage')));
const StateOfMatchingPage = Loadable(lazyWithRetry(() => import('views/dashboard/StateOfMatchingView/StateOfMatchingPage')));
const ReportsConfigPage = Loadable(lazyWithRetry(() => import('views/dashboard/ReportsConfigView/ReportsConfigPage')));
const MMSValidationPage = Loadable(lazyWithRetry(() => import('views/dashboard/MMSValidation/MMSValidationPage')));
const MMSAccuracyPage = Loadable(lazyWithRetry(() => import('views/dashboard/MMSAccuracy/MMSAccuracyPage')));
const ProfilPage = Loadable(lazyWithRetry(() => import('views/ProfilPage')));
const ProductDetailsPage = Loadable(lazyWithRetry(() => import('views/dashboard/ProductDetails/ProductDetailsPage')));
const BrandsVisibilityPage = Loadable(lazyWithRetry(() => import('views/brand/BrandsVisibility/BrandsVisibilityPage')));
const BrandsOverviewPage = Loadable(lazyWithRetry(() => import('views/brand/BrandsOverview/BrandsOverviewPage')));
const BrandsWebsitePage = Loadable(lazyWithRetry(() => import('views/brand/BrandsWebsite/BrandsWebsitePage')));
const BrandPricingPage = Loadable(lazyWithRetry(() => import('views/brand/BrandPricing/BrandPricingPage')));
const ProductsStatusPage = Loadable(lazyWithRetry(() => import('views/dashboard/ProductsStatus/ProductsStatusPage')));
const AssortmentScoringPage = Loadable(lazyWithRetry(() => import('views/dashboard/AssortmentScoring/AssortmentScoringPage')));
const AssortmentScoringOverviewPage = Loadable(
    lazyWithRetry(() => import('views/dashboard/AssortmentScoringOverview/AssortmentScoringOverviewPage'))
);
const GFKCoveragePage = Loadable(lazyWithRetry(() => import('views/dashboard/GFKCoverage/GFKCoveragePage')));

const storedIsBrand = localStorage.getItem('isBrand');
const storedIsVisibility = localStorage.getItem('isVisibility');
const checkIsBrand =
    storedIsBrand && String(storedIsBrand) == 'true' && storedIsVisibility && String(storedIsVisibility) == 'true' ? true : false;
const defaultRoute = checkIsBrand ? '/brands/overview' : '/assortment/relevant';

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Navigate replace to={defaultRoute} />
        },
        {
            path: '/assortment',
            element: <OverviewPage title="Assortment | Suite 360" />
        },
        {
            path: '/brands/overview',
            element: (
                <ProtectedRoute routeName="brandsoverview">
                    <BrandsOverviewPage title="Brand Overview | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/gfkcoverage',
            element: <GFKCoveragePage title="GFK Coverage | Suite 360" />
        },
        {
            path: '/assortment/scoring',
            element: (
                // <ProtectedRoute routeName="productsscoring">
                <AssortmentScoringPage title="Products Scoring | Suite 360" />
                // </ProtectedRoute>
            )
        },
        {
            path: '/assortment/scoring-overview',
            element: <AssortmentScoringOverviewPage title="Assortment Scoring Overview | Suite 360" />
        },
        {
            path: '/brands/visibility',
            element: (
                <ProtectedRoute routeName="brandsvisibility">
                    <BrandsVisibilityPage title="Brand Visibility | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/brands/pricing',
            element: (
                <ProtectedRoute routeName="brandpricing">
                    <BrandPricingPage title="Brand Pricing by Product | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/brands/website',
            element: (
                <ProtectedRoute routeName="brandswebsite">
                    <BrandsWebsitePage title="Brands Pricing by Website | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/product',
            element: <ProductDetailsPage title="Product Details | Suite 360" />
        },
        {
            path: '/productstatus',
            element: (
                <ProtectedRoute routeName="productstatus">
                    <ProductsStatusPage title="Product Status | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/listing',
            element: (
                <ProtectedRoute routeName="listing">
                    <ListingWizard title="Listing Wizard | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/googlelisting',
            element: (
                <ProtectedRoute routeName="googlelisting">
                    <GoogleListing title="Google Listing Wizard | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/catalog',
            element: (
                <ProtectedRoute routeName="freshcatalog">
                    <FreshCatalog title="Last Crawl | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/catalog/availability',
            element: (
                <ProtectedRoute routeName="freshcatalog">
                    <AvailabilityCatalogPage title="Availability Crawl | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/catalog/shipping',
            element: (
                <ProtectedRoute routeName="freshcatalog">
                    <ShippingCatalogPage title="Shipping Crawl | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/relevant',
            element: (
                <ProtectedRoute routeName="relevance">
                    <Relevance title="Relevance Products | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/gfkproducts',
            element: (
                <ProtectedRoute routeName="gfkproducts">
                    <AllGFKProducts title="GFK Products | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/opportunities',
            element: (
                <ProtectedRoute routeName="opportunities">
                    <OpportunitiesPage title="Products Opportunities | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/betterway',
            element: <BetterWayPage title="Products BetterWay | Suite 360" />
        },
        {
            path: '/assortment/gfkopportunities',
            element: (
                <ProtectedRoute routeName="gfkOpportunities">
                    <GfkOpportunitiesPage title="GFK Opportunities | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/marketshare',
            element: (
                <ProtectedRoute routeName="marketshare">
                    <MarketSharePage title="Market Share | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/marketsharebybrands',
            element: (
                <ProtectedRoute routeName="marketsharebybrands">
                    <MarketShareByBrandsPage title="Market Share By Brands | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/exclusive',
            element: (
                <ProtectedRoute routeName="exclusive">
                    <ExclusiveProductsPage title="Exclusives Products | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/newlydiscovered',
            element: (
                <ProtectedRoute routeName="newproducts">
                    <NewlyDiscoveredPage title="New In The Market | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/newdescoveries',
            element: (
                <ProtectedRoute routeName="newproducts">
                    <NewProducts title="New Products | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/amazonanalytics/amazonbestseller',
            element: (
                <ProtectedRoute routeName="amazonanalytics">
                    <AmazonBestSellerPage title="Amazon Best Sellers | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/assortment/bestrated',
            element: (
                <ProtectedRoute routeName="bestrated">
                    <BestRated title="Best Rated | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/custom/collections',
            element: (
                <ProtectedRoute routeName="campaignmanager">
                    <CampaignManager title="Custom Collections | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/pricing/changes',
            element: <DailyChanges title="Daily Changes | Suite 360" />
        },
        {
            path: '/pricing/competitors',
            element: <CompetitorPricing title="Competitors pricing | Suite 360" />
        },
        {
            path: '/pricing/multivariate',
            element: <MultivariateAnalysis title="Product Multivariates | Suite 360" />
        },
        {
            path: '/exportationlist',
            element: <ExportationListPage title="Exportation list | Suite 360" />
        },
        {
            path: '/settings/stats',
            element: <StateOfMatchingPage title="State of Matching | Suite 360" />
        },
        {
            path: '/settings/reports',
            element: (
                <ProtectedRoute routeName="reporttracking">
                    <ReportsConfigPage title="Reports Config | Suite Dashboard" />
                </ProtectedRoute>
            )
        },
        {
            path: '/validation',
            element: (
                <ProtectedRoute routeName="mmsvalidator">
                    <MMSValidationPage title="MMS Validation | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/mms-accuracy',
            element: (
                <ProtectedRoute routeName="mmsaccuracy">
                    <MMSAccuracyPage title="MMS Accuracy | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/content/rating',
            element: (
                <ProtectedRoute routeName="contentRating">
                    <RatingPage title="Rating and Reviews | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/content/overview',
            element: (
                <ProtectedRoute routeName="contentOverview">
                    <ContentOverviewPage title="Content Analytics Overview | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/content/analytics',
            element: (
                <ProtectedRoute routeName="content">
                    <ContentAnalyticsPage title="Advanced Content Analytics | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/content/titles',
            element: (
                <ProtectedRoute routeName="contentTitle">
                    <TitleAnalysisPage title="Title Analytics | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/content/qualityscore',
            element: (
                <ProtectedRoute routeName="contentQaulityScore">
                    <QualityScorePage title="Content Quality Score | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/content/contentdetails',
            element: (
                <ProtectedRoute routeName="/content/contentdetails">
                    <ContentDetailsPage title="Content Details | Suite 360" />
                </ProtectedRoute>
            )
        },
        {
            path: '/settings/profile',
            element: <ProfilPage title="Profile - Suite 360" />
        },
        {
            path: '/ssologin',
            element: <SSOLogin title="Login - Suite 360" />
        },
        {
            path: '/login',
            element: <Navigate to={defaultRoute} />
        },
        {
            path: '/login/oauthredirect',
            element: <Navigate to={defaultRoute} />
        },
        {
            path: '/login/sso',
            element: <Navigate to={defaultRoute} />
        },
        {
            path: '/register',
            element: <Navigate to={defaultRoute} />
        },
        {
            path: '/forgot',
            element: <Navigate to={defaultRoute} />
        },
        {
            path: '/new-password',
            element: <Navigate to={defaultRoute} />
        }
    ]
};

export const ErrorRoutes = [
    {
        path: '/unauthorized',
        element: <ErrorPage title="Unauthorized | Suite 360" subTitle={'403'} description={'Sorry, you do not have access to this page.'} />
    },
    {
        path: '*',
        element: <ErrorPage title="Page not found | Suite 360" subTitle={'404'} description={'This page could not be found.'} />
    }
];

export default MainRoutes;
