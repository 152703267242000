import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NextLink, Operation } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';

import config from 'config';

const cookie: string = localStorage.getItem('sessionId') || '';

const httpLink = new HttpLink({
    uri: config.graphqlAPIUrl,
    fetchOptions: {
        mode: 'cors' // no-cors, *cors, same-origin
    }
});

const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    operation.setContext({
        headers: {
            Cookie: `__UserId__token=${cookie}`
        },
        credentials: 'include'
    });
    return forward(operation);
});

setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Cookie: `__UserId__token=${cookie}`
        },
        credentials: 'include'
    };
});

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        const Unauthorized = graphQLErrors.filter((error: GraphQLError) => error.message == 'Unauthorized');
        if (Unauthorized && Unauthorized.length) {
            console.log('Unauthorized :', Unauthorized);
            const appVersion = localStorage.getItem('appVersion');
            localStorage.clear();
            if (appVersion) localStorage.setItem('appVersion', appVersion);
            console.log('Unauthorized! Redirecting...');
            window.location.href = '/login';
        }
    }
});

const appoloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache'
        },
        mutate: {
            fetchPolicy: 'no-cache'
        }
    }
});

export default appoloClient;
