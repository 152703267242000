import { Fragment, useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthWrapper from 'components/auth/AuthWrapper';
import LoginForm from './auth-forms/LoginForm';
import CountryModal from 'components/auth/CountryModal';
import {
    selectRegion,
    updateIsMMS,
    updateIsBrand,
    updatedefaultWebsite,
    updateSelectedWebsites,
    updateIsVisibility
} from 'store/slices/auth/authSlice';
import { initializeInputList, resetInputList } from 'store/slices/filter/filterSlice';
import useDocumentTitle from 'hooks/useDocumentTitle';
import Logo from 'components/common/UI/Logo';
import { AppDispatch, RootState } from 'store';
import { loginUser } from 'store/slices/auth/authActions';

function SSOLogin({ title }: { title: string }) {
    useDocumentTitle(title || 'Login | Suite 360');
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { userRegions, userWebsites, accessAccountUser } = useSelector((state: RootState) => state.auth);

    const [open, setOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const getQueryParams = (query: string) => {
        return new URLSearchParams(query);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectRegion = (region: string) => {
        let websites = [];
        const currentAccount: any = accessAccountUser.find((item: any) => item.account.config.region == region);
        let isMMSValue = false,
            isBrandValue = false,
            isVisibilityValue = false;

        if (currentAccount?.account?.config?.mms) isMMSValue = true;
        if (currentAccount?.account?.config?.isBrandAcount) isBrandValue = true;
        if (currentAccount?.account?.config?.isVisibility) isVisibilityValue = true;

        websites = userWebsites.filter((item: any) => item.region === region)[0].websites;
        const storedDefaultWebsite = websites.filter((elm: any) => elm.default)[0];

        dispatch(updateIsMMS(isMMSValue));
        dispatch(updateIsBrand(isBrandValue));
        dispatch(updateIsVisibility(isVisibilityValue));

        // update filter
        dispatch(resetInputList());

        const limitDefaultSelectedWebsites = currentAccount?.account?.config?.limitDefaultSelected;
        dispatch(updateSelectedWebsites(limitDefaultSelectedWebsites ? websites.slice(0, limitDefaultSelectedWebsites) : websites));

        dispatch(updatedefaultWebsite(storedDefaultWebsite));
        dispatch(selectRegion(region));

        const inputListData =
            isMMSValue === true
                ? [{ id: '1', fieldSearch: 'Module', checkOption: 'Is' }]
                : [{ id: '1', fieldSearch: 'Keywords', checkOption: 'Containing', includeExclude: 'Include', searchkey: [] }];

        dispatch(initializeInputList(inputListData));

        if (isBrandValue && isVisibilityValue) {
            navigate('/brands/overview', { replace: true });
        } else {
            navigate('/assortment/relevant', { replace: true });
        }
    };

    useEffect(() => {
        const queryParams = getQueryParams(location.search);

        const user = queryParams.get('user');
        const refreshToken = queryParams.get('refresh_token');
        const accessToken = queryParams.get('access_token');

        const errors = queryParams.get('error');
        console.log('error ', errors);
        console.log('user ', user);
        console.log('accessToken ', accessToken);
        console.log('refreshToken ', refreshToken);
        if (refreshToken && user && accessToken) {
            console.log('user ', user);
            console.log('accessToken ', accessToken);
            console.log('refreshToken ', refreshToken);

            dispatch(loginUser({ email: user, password: accessToken, isOAuth: true }));
        } else {
            if (errors) {
                setError(errors);
            }
        }
    }, []);

    return (
        <Fragment>
            <AuthWrapper showSSOLogin={true}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
                            <Logo width="200px" />
                        </Box>
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
                                <Typography variant="h5" color="error" fontWeight="400">
                                    {error}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LoginForm handleOpen={handleOpen} handleSelectRegion={handleSelectRegion} />
                    </Grid>
                </Grid>
            </AuthWrapper>

            <CountryModal open={open} items={userRegions || []} handleClose={handleClose} handleClick={handleSelectRegion} />
        </Fragment>
    );
}

export default SSOLogin;
