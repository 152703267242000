import { Card, Stack, Tooltip, Typography } from '@mui/material';
import getRegionDisplayName from 'utils/getRegionDisplayName';

import ReactCountryFlag from 'react-country-flag';

interface CountryCard {
    countryCode: string;
    handleClick: () => void;
}

function CountryCard({ countryCode, handleClick }: CountryCard) {
    switch (countryCode) {
        case 'SWE':
            countryCode = 'SE';
            break;
        case 'USA':
            countryCode = 'US';
            break;
        case 'UK':
            countryCode = 'GB';
            break;
        case 'ME':
            countryCode = 'AE';
            break;
    }

    const countryName = getRegionDisplayName(countryCode.toLowerCase());

    return (
        <Tooltip title={countryName} placement="top">
            <Card sx={{ px: 2, py: 1, cursor: 'pointer' }} elevation={4} onClick={handleClick}>
                <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                    <ReactCountryFlag
                        countryCode={countryCode}
                        svg
                        style={{
                            width: '50px',
                            height: 'auto'
                        }}
                    />
                    <Typography component="p" variant="body1" fontSize={14} noWrap overflow="ellipsis">
                        {countryCode == 'AE' ? 'ME' : countryCode}
                    </Typography>
                </Stack>
            </Card>
        </Tooltip>
    );
}

export default CountryCard;
