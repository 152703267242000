import { useState, MouseEvent, useEffect } from 'react';

/** MUI Import */
import {
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    Link
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/** Third Party Import */
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { loginUser, accessAccountUser } from 'store/slices/auth/authActions';
import { updatePrivacy } from 'store/slices/auth/authSlice';
import PrivacyConditionsModal from 'components/auth/PrivacyConditionsModal';
import { ADD_USER_ACCEPT_PRIVACY } from 'services/authService/queries';
import { AppDispatch } from 'store';

interface LoginFormProps {
    handleOpen: () => void;
    handleSelectRegion: (region: string) => void;
}

function LoginForm({ handleOpen, handleSelectRegion }: LoginFormProps) {
    const dispatch: AppDispatch = useDispatch();

    const { userRegions, loading, userInfo, error, isPrivacy, isLoggedIn } = useSelector((state: any) => state.auth);

    const [checked, setChecked] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [openPrivacy, setOpenPrivacy] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is Required'),
        password: Yup.string().required('Password is Required')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: (values) => {
            dispatch(loginUser({ email: values.email, password: values.password }));
        }
    });

    const [acceptPrivacy, { loading: loadingPrivacy, error: errorPrivacy, data }] = useMutation(ADD_USER_ACCEPT_PRIVACY, {
        variables: {
            user: userInfo?.id,
            ip: '',
            address: ''
        }
    });

    /** Functions  */
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClosePrivacy = () => {
        setOpenPrivacy(false);
    };

    const handleAcceptPrivacy = async () => {
        const data = await fetch('https://geolocation-db.com/json/')
            .then((res) => {
                return res.json();
            })
            .catch((err) => {
                console.log('error during getting ip infos :', err);
                return {
                    IPv4: '',
                    city: '',
                    ['country_name']: ''
                };
            });
        acceptPrivacy({
            variables: {
                user: userInfo?.id,
                ip: data.IPv4,
                address: `${data.city} ${data.country_name}`
            }
        });
    };

    const handleOpenPrivacy = () => {
        setOpenPrivacy(true);
    };

    const showRegionModal = () => {
        const userInfoData = localStorage.getItem('userInfo');

        if (isPrivacy === true && isLoggedIn === true && userInfoData) {
            if (userRegions.length > 1) {
                handleOpen();
            }
            if (userRegions.length == 1) {
                handleSelectRegion(userRegions[0]);
            }
        }

        if (isPrivacy === false && userRegions.length > 0 && isLoggedIn === true && userInfoData) {
            handleOpenPrivacy();
        }
    };

    const fetchAccess = () => {
        if (userInfo?.id) {
            dispatch(accessAccountUser({ userId: userInfo.id }));
        }
    };

    useEffect(() => {
        const appVersion = localStorage.getItem('appVersion');
        localStorage.clear();
        if (appVersion) {
            localStorage.setItem('appVersion', appVersion);
        }
    }, []);

    useEffect(() => {
        showRegionModal();
    }, [userRegions, isPrivacy]);

    useEffect(() => {
        fetchAccess();
    }, [userInfo]);

    useEffect(() => {
        if (data) {
            if (data.addUserAcceptPrivacy.accepted === true) dispatch(updatePrivacy(true));
            setOpenPrivacy(false);
        }
    }, [loadingPrivacy]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={0.3}>
                            <InputLabel htmlFor="email-login">Email Address</InputLabel>
                            <OutlinedInput
                                id="email-login"
                                type="email"
                                value={formik.values.email}
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder="Enter email address"
                                fullWidth
                                error={Boolean(formik.touched.email && formik.errors.email)}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {formik.errors.email}
                                </FormHelperText>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={0.3}>
                            <InputLabel htmlFor="password-login">Password</InputLabel>
                            <OutlinedInput
                                fullWidth
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                id="-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                name="password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="small"
                                        >
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                placeholder="Enter password"
                            />
                            {formik.touched.password && formik.errors.password && (
                                <FormHelperText focused error id="standard-weight-helper-text-password-login">
                                    {formik.errors.password}
                                </FormHelperText>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        name="checked"
                                        color="primary"
                                        size="small"
                                    />
                                }
                                label={<Typography variant="body2">Remember me</Typography>}
                            />
                            <Typography variant="body2">
                                <Link component={RouterLink} to="/forgot" color={'text.primary'} underline="hover">
                                    Forgot Password ?
                                </Link>
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <LoadingButton
                            disableElevation
                            disabled={loading}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                        >
                            Login
                        </LoadingButton>
                        {error && (
                            <FormHelperText error sx={{ textAlign: 'center' }}>
                                {error?.message}
                            </FormHelperText>
                        )}
                        {errorPrivacy && (
                            <FormHelperText error sx={{ textAlign: 'center' }}>
                                Somthing Wrong
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </form>
            <PrivacyConditionsModal
                open={openPrivacy}
                loading={loadingPrivacy}
                handleClose={handleClosePrivacy}
                handleClick={handleAcceptPrivacy}
            />
        </>
    );
}

export default LoginForm;
