import { SyntheticEvent, useEffect, useState } from 'react';
import {
    AppBar,
    IconButton,
    Toolbar,
    Theme,
    useMediaQuery,
    Stack,
    Divider,
    Box,
    Autocomplete,
    MenuItem,
    Checkbox,
    ListItemText,
    TextField,
    SxProps,
    ListItemButton,
    ListItemIcon
} from '@mui/material';
import { FiMenu, FiMoon, FiSun, FiUser } from 'react-icons/fi';

import { useDispatch } from 'react-redux';

import { usePopover } from 'hooks/use-propover';
import { changeTheme, updateSelectedWebsites } from 'store/slices/auth/authSlice';
import Logo from 'components/common/UI/Logo';
import { useAppSelector } from 'store/hooks';
import { submitFilter } from 'store/slices/filter/filterSlice';
import WebsiteImage from 'components/product/Websites/WebsiteImage';
import SettingsPropover from './SettingsPropover';
import CountryPopover from './CountryPopover';
import BrandIcon from './BrandIcon';
import { AiOutlineSlack } from 'react-icons/ai';
import { Location, useLocation, useNavigate } from 'react-router-dom';

interface HeaderProps {
    sx: SxProps;
    customClass?: string;
    handleChangeDesktopDrawer?: () => void;
    handleOpenMobileDrawer?: () => void;
    handleCloseMobileDrawer?: () => void;
    handleCloseDesktopDrawer?: () => void;
}

function Header({
    sx,
    customClass,
    handleChangeDesktopDrawer,
    handleOpenMobileDrawer,
    handleCloseMobileDrawer,
    handleCloseDesktopDrawer
}: HeaderProps) {
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const accountPopover = usePopover();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location: Location = useLocation();

    const [scoringRoutItemShow, setScoringRoutItemShow] = useState(false);

    const { accessAccountUser, selectedRegion, userWebsites, selectedWebsites, isDark, userBrands } = useAppSelector((state) => state.auth);
    const websites = userWebsites.filter((item: any) => item.region === selectedRegion)[0]?.websites;

    const [selectAllWebsite, setSelectAllWebsite] = useState(websites.length === selectedWebsites.length ? true : false);

    const regionBrands = userBrands.filter((ub: any) => ub.region == selectedRegion);
    const defaultBrand = regionBrands.length ? regionBrands[0].brands.find((el: any) => el.default) : null;

    const scoringRoutItem = {
        id: 'productsscoring',
        title: 'SizeWize Analyzer',
        path: '/assortment/scoring-overview',
        icon: <AiOutlineSlack size={20} />
    };

    const toggleTheme = () => {
        const storedTheme = isDark ? 'light' : 'dark';

        localStorage.setItem('theme', storedTheme);
        dispatch(changeTheme());
    };

    const handleSelectedWebsitesChange = (event: SyntheticEvent<Element, Event>, newValue: any[]) => {
        if (newValue.length) {
            const updatedWebsites = newValue;

            dispatch(updateSelectedWebsites(updatedWebsites));
            dispatch(submitFilter());
        } else if (newValue.length === 0) {
            dispatch(updateSelectedWebsites(newValue));
        }
    };
    const handleSelectAllWebsite = () => {
        if (!selectAllWebsite) {
            dispatch(updateSelectedWebsites(websites));
            dispatch(submitFilter());
        } else dispatch(updateSelectedWebsites([]));

        setSelectAllWebsite((prev) => !prev);
    };

    const handleClick = (item: any) => {
        navigate(item?.path, { replace: true });
        if (handleCloseMobileDrawer) handleCloseMobileDrawer();
        if (handleCloseDesktopDrawer) handleCloseDesktopDrawer();
    };

    useEffect(() => {
        // hide or show the size wize analyzer
        const accountComponents: AccountAccess[] = accessAccountUser.filter(
            (item: AccountAccess) => item?.account?.config?.region == selectedRegion
        );

        if (accountComponents.length) {
            const allowedComponents = accountComponents[0]?.account?.components?.filter((comp) => comp.allowed)?.map((comp) => comp.name);

            if (allowedComponents?.includes(scoringRoutItem.id as any)) {
                setScoringRoutItemShow(true);
            }
        }

        if (websites.length === selectedWebsites.length) setSelectAllWebsite(true);
        else setSelectAllWebsite(false);
    }, [selectedWebsites]);

    return (
        <>
            <AppBar sx={sx} elevation={0} className={customClass}>
                <Toolbar>
                    {lgUp && (
                        <Box sx={{ width: '250px' }}>
                            <Logo width="150px" />
                        </Box>
                    )}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleChangeDesktopDrawer}
                        size="large"
                        sx={{
                            display: {
                                lg: 'flex',
                                xs: 'none'
                            }
                        }}
                    >
                        <FiMenu size={24} />
                    </IconButton>

                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleOpenMobileDrawer}
                        sx={{
                            display: {
                                lg: 'none',
                                xs: 'flex'
                            }
                        }}
                    >
                        <FiMenu size={20} />
                    </IconButton>

                    {!lgUp && <Box sx={{ flexGrow: 1 }} />}
                    {!lgUp && (
                        <Box sx={{ width: '150px', pt: '8px' }}>
                            <Logo width="150px" />
                        </Box>
                    )}

                    {scoringRoutItemShow && (
                        <Box flexGrow={1} sx={{ width: '150px', pt: '8px' }}>
                            <ListItemButton
                                component="li"
                                onClick={() => handleClick(scoringRoutItem)}
                                selected={location.pathname === scoringRoutItem.path}
                                sx={{
                                    mb: 1,
                                    width: 'fit-content',
                                    pl: '15px',
                                    pr: '8px',
                                    pt: '0px',
                                    pb: '0px',
                                    ...(location.pathname === scoringRoutItem.path && {
                                        color: 'white',
                                        backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                    })
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        ...(location.pathname === scoringRoutItem.path && {
                                            color: 'white'
                                        })
                                    }}
                                >
                                    {scoringRoutItem.icon}
                                </ListItemIcon>
                                <ListItemText>{scoringRoutItem.title}</ListItemText>
                            </ListItemButton>
                        </Box>
                    )}

                    <Box flexGrow={1} />
                    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                        <CountryPopover />

                        <Autocomplete
                            size="small"
                            multiple
                            clearOnEscape
                            id="selected-websites"
                            onChange={handleSelectedWebsitesChange}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={selectedWebsites || null}
                            renderTags={() => null}
                            options={[{ name: 'Select All', id: 'all' }, ...websites]}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.name}
                            sx={{
                                width: 210
                            }}
                            disableClearable
                            renderOption={(props, option: any) => {
                                return option.name == 'Select All' ? (
                                    <MenuItem selected={false} sx={{ p: 0 }} onClick={handleSelectAllWebsite} key={option?.id}>
                                        <Checkbox size="small" sx={{ mr: 1 }} checked={selectAllWebsite} />
                                        <ListItemText>Select All</ListItemText>
                                    </MenuItem>
                                ) : (
                                    <Box
                                        key={option?.id}
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                            my: 0.3
                                        }}
                                        {...props}
                                    >
                                        <WebsiteImage websiteId={option?.id} websiteUrl={option.url} name={option?.name} />
                                        {option?.name}
                                    </Box>
                                );
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        InputProps={params.InputProps}
                                        placeholder={`${selectedWebsites.length} Selected Websites`}
                                    />
                                );
                            }}
                        />
                        {/* brand icon if it's brand account */}
                        {defaultBrand?.name && <BrandIcon brandName={defaultBrand.name} brandDomain={`${defaultBrand.name}.com`} />}

                        <Divider orientation="vertical" flexItem />
                        <IconButton aria-label="theme" size="large" onClick={toggleTheme} color="inherit">
                            {isDark ? <FiSun size={20} /> : <FiMoon size={20} />}
                        </IconButton>
                        <IconButton
                            aria-label="settings"
                            size="large"
                            onClick={accountPopover.handleOpen}
                            ref={accountPopover.anchorRef}
                            color="inherit"
                        >
                            <FiUser size={20} />
                        </IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            <SettingsPropover
                anchorEl={accountPopover.anchorRef.current}
                open={accountPopover.open}
                handleClose={accountPopover.handleClose}
            />
        </>
    );
}

export default Header;
